<template>
  <section>
    <router-view></router-view>
  </section>
</template>
<script>
export default {
  name: 'pagosRecibidos',
  setup () {
    return {
    }
  }
}
</script>
<style>
::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-input-placeholder { /* Edge */
  color: gray;
}
</style>
